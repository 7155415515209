<template>
  <div class="page">
    <div class="create">
      <!-- <el-button plain type="normal" size="mini" @click="close">返回</el-button> -->
      <el-button plain type="primary" size="mini" @click="print">打印工单</el-button>
    </div>
    <el-row class="outer-row" id="print">
      <el-col :span="8">
        <div class="pdl20div">
          <el-descriptions title="事件信息" :column="1">
            <el-descriptions-item label="事件类型"
              >{{device.eventCodeIdConfirmName}}</el-descriptions-item
            >
            <el-descriptions-item label="地址"
              >{{device.eventAddressConfirm}}</el-descriptions-item
            >
            <el-descriptions-item label="说明">{{device.eventDescribe}}</el-descriptions-item>
            <!-- <el-descriptions-item label="现场照片" :contentStyle="{display:'block'}"> -->
              <!-- <div>
                现场照片
              </div>
                 <div class="imgContain" v-if="device.imgUrl" >
                  <span v-for="(fourths,int4) in device.imgUrl.split(',')" :key="int4"> -->
                    <!-- <img style="width: 100px; height: 100px;border: 1px solid #ECEDF1;" :src="fourths" 
                    v-for="(fourths,int4) in device.imgUrl.split(',')" 
                    @click="showImg(fourths,device.imgUrl.split(','))"
                    :key="int4" alt=""/> -->
                    <!-- <el-image
                      style="width: 100px; height: 100px;border: 1px solid #ECEDF1;"
                      :src="fourths"
                      :preview-src-list="device.imgUrl.split(',')"
                    >
                    </el-image>
                  </span>
                 </div> -->
                 <!-- <div class="imgContain" v-if="device.fileUrl">
                    <a href="" target="_black" v-for="(fourths,int4) in device.fileUrl.split(',')"  :key="int4" >
                    {{fourths}}
                    </a>
                 </div> -->

            <!-- </el-descriptions-item> -->
             <!-- <el-descriptions-item label="附件">
               <div class="imgContain" v-if="device.fileUrl">
                    <span  v-for="(fourths,int4) in device.fileUrl.split(',')" :key="int4">
                    <el-link :underline="false" :href="fourths" target="_blank"><span class="content-text">{{ fourths }}</span></el-link>
                    </span>
                 </div>
              </el-descriptions-item> -->
          </el-descriptions>
          <div style="    color: #616161;">
                现场照片:
              </div>
          <div class="imgContain" v-if="device.imgUrl" >
                  <span v-for="(fourths,int4) in device.imgUrl.split(',')" :key="int4">
                    <!-- <img style="width: 100px; height: 100px;border: 1px solid #ECEDF1;" :src="fourths" 
                    v-for="(fourths,int4) in device.imgUrl.split(',')" 
                    @click="showImg(fourths,device.imgUrl.split(','))"
                    :key="int4" alt=""/> -->
                    <el-image
                      style="width: 100px; height: 100px;border: 1px solid #ECEDF1;"
                      :src="fourths"
                      :preview-src-list="device.imgUrl.split(',')"
                    >
                    </el-image>
                  </span>
                 </div>
                <div style="    color: #616161;">
                附件:
              </div>
               <div class="imgContain" v-if="device.fileUrl">
                    <span  v-for="(fourths,int4) in device.fileUrl.split(',')" :key="int4">
                    <el-link :underline="false" :href="fourths" target="_blank"><span class="content-text">{{ fourths }}</span></el-link>
                    </span>
                 </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="right-part">
          <div class="title">采集信息</div>
          <div class="right-content">
            <el-popover width="300" @show="detailAddress(device.location,'container')" style="float:right;margin-right: 40px;">
                    <div :id="'container'" style="height:300px;width:300px"></div>
                    <el-button type="primary" plain  size="mini" slot="reference">打卡位置</el-button>
                  </el-popover>
                <!-- <el-button type="primary" plain  size="mini" style="float:right;margin-right: 40px;" @click="detailAddress(device.location,'container')">打卡位置</el-button> -->
                  <div>
                    <el-descriptions direction='vertical' :column="1" v-for="(item1,second) in form" :key="second">
                      <el-descriptions-item :label="'● '+item1.itemName" :label-style="{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}">
                        <template v-if="item1.inputType==1 || item1.inputType==5">{{item1.note}}</template>
                        <div v-if="item1.inputType==2||item1.inputType==3">
                          <div v-for="item2 in item1.items" :key="'b'+item2.items" style="position: relative">
                            <template v-if="item2.isPitchUp">
                              <div class="isPitchUpImg">
                              <img class="icon-img" :src="item2.type==1?require('../../assets/normal1.png'):require('../../assets/warning1.png')" />
                              {{item2.content}}
                              </div>
                              <div class="describ" v-if="item2.note" style="min-width: 180px">
                                {{item2.note}}
                              </div>
                              <div class="imgContain" v-if="item2.url">
                                <!-- <img style="width: 100px; height: 100px" :src="fourths" 
                                v-for="(fourths,int4) in item2.url.split(',')" 
                                @click="showImg(fourths)"
                                :key="int4" alt=""/> -->
                                <span v-for="(fourths,int4) in item2.url.split(',')" :key="int4">
                                <el-image
                                  style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                                  :src="fourths"
                                  :preview-src-list="item2.url.split(',')"
                                >
                                </el-image>
                                </span>
                              </div>
                              <!-- 关联项 -->
                              <!-- <div v-for="item3 in item2.junior" :key="item3.juniorId"> -->
                                <el-descriptions direction='vertical' :column="1" v-for="item3 in item2.junior" :key="item3.juniorId" >
                                  <el-descriptions-item :label-style="{color:'#444444',fontWeight: 'bold'}"
                                          :label="'● '+item3.juniorName">
                                    <template v-if="item3.juniorInputType==1 || item3.juniorInputType==5">{{item3.note}}</template>
                                    <div v-if="item3.juniorInputType==2||item3.juniorInputType==3">
                                      <div v-for="item4 in item3.juniors" :key="'b'+item4.juniorsId">
                                        <template  v-if="item4.isPitchUp">
                                          <div class="isPitchUpImg">
                                          <img class="icon-img" :src="item4.juniorsType==1?require('../../assets/normal1.png'):require('../../assets/warning1.png')" />
                                          {{item4.juniorsContent}}
                                          </div>
                                          <div class="describ" v-if="item4.note" style="min-width: 180px">
                                            {{item4.note}}
                                          </div>
                                          <div class="imgContain" v-if="item4.url">
                                            <!-- <img style="width: 100px; height: 100px" :src="firsts" 
                                            v-for="(firsts,int1) in item4.url.split(',')" 
                                             @click="showImg(firsts)"
                                            :key="int1" alt=""/> -->
                                            <span v-for="(firsts,int1) in item4.url.split(',')" :key="int1">
                                            <el-image
                                              style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                                              :src="firsts"
                                              :preview-src-list="item4.url.split(',')"
                                            >
                                            </el-image>
                                            </span>
                                          </div>
                                        </template>
                                      </div>
                                    </div>
                                    <div v-if="item3.juniorInputType==4">
                                      <div class="imgContain" v-if="item3.url">
                                        <!-- <img style="width: 100px; height: 100px" :src="sectids" 
                                        v-for="(sectids,int2) in item3.url.split(',')" 
                                        @click="showImg(sectids)"
                                        :key="int2" alt=""/> -->
                                        <span v-for="(sectids,int2) in item3.url.split(',')" :key="int2">
                                            <el-image
                                              style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                                              :src="sectids"
                                              :preview-src-list="item3.url.split(',')"
                                            >
                                            </el-image>
                                            </span>
                                      </div>
                                    </div>
                                  </el-descriptions-item>
                                </el-descriptions>
                              <!-- </div> -->
                            </template>
                          </div>
                        </div>
                        <div class="right" v-if="item1.inputType==4">
                          <div class="imgContain" v-if="item1.url">
                            <!-- <img style="width: 100px; height: 100px" :src="thirdlys" 
                            v-for="(thirdlys,int2) in item1.url.split(',')" 
                             @click="showImg(thirdlys)"
                            :key="int2" alt=""/> -->
                            <span v-for="(thirdlys,int2) in item1.url.split(',')" :key="int2">
                            <el-image
                              style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                              :src="thirdlys"
                              :preview-src-list="item1.url.split(',')"
                            >
                            </el-image>
                            </span>
                          </div>
                        </div>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
          
          </div>
        </div>
      </el-col>
    </el-row>
    <show-img :imgList="imgList" ref="imgList"></show-img>
  </div>
</template>
 
<script>
import {dataInfo} from "@/RequestPort/checkTask/polling";
import showImg from "@/components/showImg.vue";
import { dataPage} from "@/RequestPort/checkTask/task";
export default {
  name: "unresidentDetail",
  components: { showImg },
  data() {
    return {
      activeNames: 0,
      srcList:[],
      // 表单
      form:[],
      dataId:'',
      // 图片
      imgList: [],
      device:{},
      deviceTwo:{},
      // 获取左半面信息查询条件
      search: {
        current: 1,
        size: 100,
      },
    };
  },
  mounted(){
    this.deviceTwo = JSON.parse(this.$route.query.row)
    console.log(this.deviceTwo)
    if(this.$route.query.id){
      this.dataId = this.$route.query.id
       this.particulars(this.$route.query.id)
       this.partilist(this.$route.query.row)
     }
  },
  methods: {
    /**
     * 获取列表信息
     */
    partilist(row){
      let dataids
      if(this.deviceTwo.eventDataId){
        dataids = this.deviceTwo.eventDataId
      } else {
        // 巡检结果事件
        dataids = this.deviceTwo.id
      }
      this.search.dataId = dataids
      dataPage(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.device = records[0] || {}
        }
      });
    },
    /**
     * 获取采集信息
     */
    particulars(val){
      let dataids
      // 事件管理传过来的
      if(this.deviceTwo.eventDataId){
        dataids = this.deviceTwo.eventDataId
      } else {
        // 巡检结果事件
        dataids = this.deviceTwo.id
      }
      dataInfo({id:val,dataId:dataids}).then(res => {
        
        this.form = res.data
      })
    },
    print(){
      this.$router.push({
          path: "/home/evntPollingPrint",
          query: { id: this.dataId,row:JSON.stringify(this.device)},
        });
    },
    /**
     * 图片地址放大
     */
    showImg(file) {
      if (file) {
          this.$refs.imgList.setdialogVisible(true);
          this.imgList = [file];
      } else {
        this.$message.error("暂无文件");
      }
    },
    detailAddress(e,index){
      // this.$refs.mapposition.setMapCenter({
      //   checkinLocation: e
      // })
      console.log(e)
      if (e) {
          let map = new AMap.Map(index, {
            zoom: 15,//级别
            center: [Number(e.split(' ')[0]),Number(e.split(' ')[1])]
          });
          let marker = new AMap.Marker({
              position:[Number(e.split(' ')[0]),Number(e.split(' ')[1])]//位置
          })
          map.add(marker);//添加到地图

      } else {
        this.$message.error('无打卡位置信息')
      }
    },
    close() {
      this.$router.back(-1)
    },
  },
};
</script>
 
<style scoped lang="less">
.page {
  width: 99%;
  // height: 100%;
    height: calc(100vh - 91px);
  overflow: auto;
  background: #fff;
  padding:10px 20px;
  margin: 10px;
  box-sizing: border-box;
  // .pdl20div {
  //   /deep/.el-descriptions {
  //     .el-descriptions__body {
  //       padding-left: 20px;
  //     }
  //   }
  // }
  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
    .colsite {
      margin-bottom: 20px;
    }
  }
  .create {
    width: 100%;
    height: 50px;
    // background-color: burlywood;
    display: flex;
    flex-direction: row;
    align-items: center;
     margin-bottom: 5px;
    .create_size {
      font-size: 20px;
      font-weight: 500;
      color: #252700;
      margin-left: 20px;
    }
  }
  .box {
    width: 96%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;

      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        padding: 10px;
        margin-bottom: 20px;
      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.right-part {
  padding-left: 30px;
  height: calc(100% - 50px);
  overflow: auto;
  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .right-content {
    padding-left: 10px;
    .first-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      div {
        width: 50%;
        margin-bottom: 29px;
        > span {
          color: #545454;
        }
        button {
          padding: 0;
          margin-left: 40px;
        }
      }
    }
  }
  .infoCollect {
    // border: 1px solid #ECECEC;
    padding: 33px;
    .item-title::before {
      content: '| ';
      color: #7CBAFF;
    }
    /deep/.el-descriptions {
      padding: 18px 63px;
      .el-descriptions-item__label:not(.is-bordered-label) {
        color: #545454;
        width: 95px;
      }
      .el-descriptions-item__content {
        display: block;
        .ques1,.ques2 {
          line-height: 30px;
          img {
            width: 16px;
            height: 16px;
            vertical-align: text-top;
          }
        }
        .ques2 {
          padding-left: 20px;
        }
      }
    }
  }
  .imgContain {
    .el-image {
      margin: 0 10px 10px 0;
    }
  }
  .userConfirm {
    margin-top: 24px;
    display: flex;
    .left {
      margin-right: 32px;
    }
  }
}
.outer-row {
  height: calc(100% - 50px);
  .el-col {
    height: 100%;
  }
  .el-col:first-child {
    border-right: 1px solid #f1f1f1;
  }
}
/deep/.el-descriptions__title::before,
.title::before {
  content: "| ";
  font-weight: bold;
  color: #1082ff;
}
.icon-img {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 5px;
}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
  padding-bottom: 8px;
}
/deep/.el-descriptions-item__label{
  color:#616161
}
/deep/.el-descriptions-item__content{
  color: #000000;
}
.el-image {
      margin: 0 10px 10px 0;
    }
    .describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  }
  .imgContain{
	margin: 0 14px;
  }
  .isPitchUpImg{
    display: flex;
    justify-content: flex-start;
    align-content: center;
  }
</style>